import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AnimalItem from "../Home/AnimalItem";
import ViewStreamSharpIcon from "@mui/icons-material/ViewStreamSharp";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import styles from "./AnimalItemListGrid.module.css";
import { petCardDataGenerator } from "../../utils/Utils";
import { Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown,  faAngleUp } from "@fortawesome/free-solid-svg-icons";

export const AnimalItemListSearchGrid = ({
  items,
  pending = true,
  dataloadStart = false,
  keyword = null,
  birthdate = null,
  setSearchDone = null,
  animalBirthFilter = null
}) => {
  const [animalListNew, setAnimalListNew] = useState([]);
  const [showReply, setShowReply] = useState(false);
  const [showAnimalCardGrid, setShowAnimalCardGrid] = useState(false);
  const [breaderItemTotalCount, setBreaderItemTotalCount] = useState("◯◯◯◯");
  const { width } = useWindowDimensions();
  const [sortByNew, setShortByNew] = useState(true);
  const animalItemContainerRef = useRef(null);

  useEffect(() => {
    let petArray = new Array();

    if (items && items.length > 0) {
      setSearchDone(true);
      items.forEach((item) => {
        if (birthdate) {
          const queryDateMonthDay = `${
            birthdate.getMonth() + 1
          }-${birthdate.getDate()}`;
          const birthdayMonthDay = item.animal_birthDay
            ? item.animal_birthDay.substring(5)
            : null;

          if (birthdayMonthDay != queryDateMonthDay) {
            return;
          }
        }

        if (animalBirthFilter) {
          if (!isDateBetween(animalBirthFilter, item.animal_birthDay)) {
            return;
          }
        }

        if (keyword) {
          const jsonString = JSON.stringify(item);
          if (!jsonString.includes(keyword)) {
            return;
          }
        }

        let data = petCardDataGenerator(item);
        // console.log(data);
        if (data) {
          petArray.push(data);
        }
      });
    } else {
      setSearchDone(false);
    }

    if (sortByNew) {
      petArray.sort((a, b) => {
        const dateA = new Date(
          a.sellPlatformAt.seconds * 1000 + a.sellPlatformAt.nanoseconds / 1e6
        );
        const dateB = new Date(
          b.sellPlatformAt.seconds * 1000 + b.sellPlatformAt.nanoseconds / 1e6
        );
        return dateA - dateB;
      });
    } else {
      petArray.sort((a, b) => {
        const dateA = new Date(
          a.sellPlatformAt.seconds * 1000 + a.sellPlatformAt.nanoseconds / 1e6
        );
        const dateB = new Date(
          b.sellPlatformAt.seconds * 1000 + b.sellPlatformAt.nanoseconds / 1e6
        );
        return dateB - dateA;
      });
    }
    setBreaderItemTotalCount(petArray.length);
    setAnimalListNew(petArray);
  }, [items, sortByNew]);

  const isDateBetween = ({ startDate, endDate }, givenDateString) => {
    if (startDate === null || endDate === null || givenDateString === null) {
      return false;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const givenDate = new Date(givenDateString);

    return givenDate >= startDateObj && givenDate <= endDateObj;
  };

  return (
    <>
      {pending && (
        <>
          <Placeholder animation="glow" className="row">
            {Array.from({ length: 30 }, (_, i) => (
              <div className="col-6 col-sm-4 mt-2" key={i}>
                <Placeholder className="w-100" style={{ height: "200px" }} />
              </div>
            ))}
          </Placeholder>
        </>
      )}

      {animalListNew.length > 0 && (
        <>
          <Container className={`px-sm-0 mx-sm-0`}>
            <Row>
              <Col sm={8}>
                <h4 className={`mb-3 mb-sm-2 ${styles.heading}`}>
                  ブリーダーの販売中のワンちゃん一覧
                </h4>
                {width > 576 && (
                  <p className={styles.countText}>
                    全{breaderItemTotalCount.toLocaleString()}件中　
                    {/*1 - 20件目を表示 */}
                  </p>
                )}
              </Col>
              {width > 576 && (
                <Col sm={4} className="d-flex align-items-center">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShortByNew(!sortByNew);
                    }}
                    className={`${styles.seeAllLink} `}
                  >
                    投稿日が新しい順
                    {sortByNew ? <FontAwesomeIcon icon={faAngleUp} /> :  <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon> }
                  </a>
                </Col>
              )}
            </Row>
          </Container>

          {width <= 576 && (
            <div className={styles.gridListmobileOptions}>
              <p>
                全{breaderItemTotalCount.toLocaleString()}件中　
                {/* 1 - 20件目を表示 */}
              </p>
              <p>表示切替</p>
              <div>
                <ViewStreamSharpIcon
                  className={styles.icon}
                  onClick={() => {
                    setShowAnimalCardGrid(false);
                  }}
                />
                <GridViewSharpIcon
                  className={styles.icon}
                  onClick={() => {
                    setShowAnimalCardGrid(true);
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}

      {animalListNew.length == 0 && !pending && (
        <>
          {dataloadStart && (
            <Container className={`px-sm-0 mx-sm-0`}>
              <Row className="d-flex justify-content-center">
                <Col sm={{ span: 8, offset: 3 }}>
                  <Alert variant="warning" className="text-center mt-3">
                    検索結果はありません。
                  </Alert>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}

      <Container className={`px-sm-0 mx-sm-0`}>
        <Row>
          {width <= 576 && (
            <Col sm={12} className="d-flex align-items-center mt-3">
              <a
                href="#"
                className={styles.seeAllLink}
                onClick={(e) => {
                  e.preventDefault();
                  setShortByNew(!sortByNew);
                }}
              >
                投稿日が新しい順
                {sortByNew ? <FontAwesomeIcon icon={faAngleUp} /> :  <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon> }
              </a>
            </Col>
          )}
          <Col sm={12}>
            <div
              ref={animalItemContainerRef}
              className={`${
                width > 576 && !showAnimalCardGrid ? styles.animalItemRow : ""
              } ${
                showAnimalCardGrid
                  ? "flex-wrap justify-content-between"
                  : "flex-column flex-sm-row"
              } d-flex `}
            >
              {animalListNew.map((item, index) => {
                if (width <= 576 && showAnimalCardGrid) {
                  return (
                    <div style={{ width: "48.2%" }} key={index} className="">
                      <div
                        className={`${styles.animalItemCard}  ${styles.animalItemCardGrid}`}
                      >
                        <AnimalItem
                          animalItemContainerRef={animalItemContainerRef}
                          data={item}
                          type="longcard"
                          show="grid"
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className={`${styles.animalItemCard} `}>
                      <AnimalItem
                        animalItemContainerRef={animalItemContainerRef}
                        data={item}
                        type="card"
                      />
                    </div>
                  );
                }
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
