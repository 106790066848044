import React, { useEffect, useState } from "react";
import styles from "../../components/Home/PopularBreeder.module.css";
import {
  Col,
  Row,
  Button,
  Container,
  Placeholder,
  Alert
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PopularBreederItem from "../../components/Home/PopularBreederItem";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCollectionDocsWithIn } from "../../hooks/useCollectionDocsWithIn";
import {
  useDocumentsByIds,
  useGetBreederWithid
} from "../../hooks/useGetBreederWithid";

const SearchBreeder = () => {
  const { width } = useWindowDimensions();
  let [breadcrumb, setBreadcrumb] = useState(null);
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const [breederList, setBreederList] = useState([]);
  const {
    documents,
    isPending: isPendingList,
    getMultipleDocs
  } = useCollectionDocsWithIn();

  const {
    documents: list,
    isPending: pendingBreeder,
    getBreederByIds
  } = useGetBreederWithid();

  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "LOOKFOR", link: "/" });
    breadcrumbItem.push({ name: "すべてのブリーダー" });
    setBreadcrumb(breadcrumbItem);
  }, []);

  const loadData = (query = false) => {
    getMultipleDocs({
      _collection: `microchip`,
      _query: query
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(urlLocation.search);
    let locationParam = searchParams.get("location");
    let breedParam = searchParams.get("breed");
    let breederParam = searchParams.get("breeder");
    if (locationParam) {
      locationParam = locationParam
        .split(",")
        .filter((item) => item.trim() !== "");
    }
    if (breedParam) {
      breedParam = breedParam.split(",").filter((item) => item.trim() !== "");
    }

    let query = {};
    let QueryArray = [];
    QueryArray.push(`sellPlatform,==,true`);

    if (locationParam && locationParam.length > 0) {
      QueryArray.push(`sellPlatform_prefecture,in,${locationParam}`);
    }
    if (breedParam && breedParam.length > 0) {
      QueryArray.push(`animal_breed,in,${breedParam}`);
    }
    if (breederParam != null && breedParam != "") {
      QueryArray.push(`currentOwnerUID,==,${breederParam}`);
    }

    query = { ...QueryArray };
    // console.log(query);
    loadData(query);
  }, [urlLocation.search]);

  const loadBreeder = (docID = []) => {
    getBreederByIds("/users", docID);
  };

  useEffect(() => {
    if (documents) {
      let docIDSet = new Set();
      documents.forEach((item) => {
        docIDSet.add(item.currentOwnerUID);
      });
      // If you need an array, convert the Set back to an array
      let docID = Array.from(docIDSet);
      loadBreeder(docID);
    }
  }, [documents]);

  useEffect(() => {
    if (list.length > 0) {
      setBreederList(list);
    }
  }, [list]);

  return (
    <div className={`${styles.grayArea} pb-2`}>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}

      <Container>
        <div className="mt-sm-4 pb-sm-5">
          <h5 className={styles.title}>ブリーダーを探す</h5>
          <Row>
            {pendingBreeder && (
              <>
                <Placeholder animation="glow">
                  {Array.from({ length: 8 }, (_, i) => (
                    <Placeholder
                      key={i}
                      sm={{ span: 12 }}
                      className="rounded-2 p-4 my-3"
                      style={{ height: "400px" }}
                    />
                  ))}
                </Placeholder>
              </>
            )}

            {!pendingBreeder && (
              <>
                {breederList.length > 0 && (
                  <>
                    {breederList.map((item, i) => {
                      return (
                        <Col key={i} sm={12} className="mt-4">
                          <PopularBreederItem
                            breeder={item}
                            fulColumnWidth={true}
                            withList={false}
                          />
                        </Col>
                      );
                    })}
                  </>
                )}

                {breederList.length == 0 && pendingBreeder && (
                  <>
                    <Col sm={{ span: 8, offset: 2 }}>
                      <Alert variant="warning" className="text-center mt-3">
                        検索結果はありません。
                      </Alert>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        </div>

        {width <= 576 && (
          <>
            <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
          </>
        )}
      </Container>
    </div>
  );
};

export default SearchBreeder;
