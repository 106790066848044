import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Placeholder, Row } from "react-bootstrap";
import AnimalItem from "./AnimalItem";
import styles from "./AnimalList.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "../../hooks/useCollection";
import { dog_breed, dog_color } from "../../utils/CatDogData";
import { petCardDataGenerator } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useGetDoc } from "../../hooks/useGetDocs";

const AnimalList = ({ moreButton = true }) => {
  const [animalListHot, setAnimalListHot] = useState([]);
  const [animalListNew, setAnimalListNew] = useState([]);
  const navigate = useNavigate();

  const animalItemContainerRef = useRef(null);
  const { documents: newPets } = useCollection(
    "microchip",
    ["sellPlatform", "==", true],
    "sellPlatformAt",
    "desc",
    8
  );

  const { documents: hotPets } = useCollection(
    "recommended",
    ["HOT", "==", true],
    "hot_position",
    "asc"
  );

  const { getSingleDocWithQuery } = useGetDoc();
  // useEffect(() => {
  //   let dummyArray = [];
  //   for (let i = 0; i < 8; i++) {
  //     let dummyData = {
  //       image: "/assets/img/animalPreview.png",
  //       tag: "hot",
  //       gender: 1, // 1: 女の子, 2: 女の子
  //       // parent_type: "里親", //Optional
  //       animal_type: "トイ・プードル",
  //       birthday: "2023/5/1",
  //       color: "ブラウン系",
  //       address: "東京都台東区東松が谷",
  //       shortInfo: "好奇心旺盛なわんこです",
  //       price: 500000
  //     };
  //     dummyArray.push(dummyData);
  //   }
  //   setAnimalListHot(dummyArray);
  // }, []);

  useEffect(() => {
    if (newPets) {
      let pets = [];
      newPets.forEach((pet) => {
        if (pet.animalID !== undefined) {
          let petData = petCardDataGenerator(pet);
          petData.tag = "new";
          pets.push(petData);
        }
      });
      setAnimalListNew(pets);
    }
  }, [newPets]);

  useEffect(() => {
    const getPetDetails = async () => {
      if (hotPets) {
        let pets = [];
        hotPets.forEach(async (petitem) => {
          if (petitem.animalID !== undefined) {
            let pet = await getSingleDocWithQuery("microchip", [
              ["animalID", "==", petitem.animalID]
            ]);
            if(pet[0]){
              let petData = petCardDataGenerator(pet[0]);
              petData.tag = "hot";
              pets.push(petData);
            }
          }
        });
        setAnimalListHot(pets);
      }
    };
    getPetDetails();
  }, [hotPets]);
  return (
    <>
      <Row ref={animalItemContainerRef}>
        {animalListHot.map((item, index) => {
          return (
            <Col
              key={index}
              md={{ span: 6 }}
              lg={{ span: 4 }}
              xl={{ span: 3 }}
              className="mt-0 mt-sm-3">
              <AnimalItem
                animalItemContainerRef={animalItemContainerRef}
                data={item}
              />
            </Col>
          );
        })}

        {!animalListNew && (
          <>
            <Placeholder animation="glow">
              {Array.from({ length: 8 }, (_, i) => (
                <Placeholder
                  key={i}
                  md={{ span: 6 }}
                  lg={{ span: 4 }}
                  xl={{ span: 3 }}
                  className="rounded-2 p-4"
                  style={{ height: "400px" }}
                />
              ))}
            </Placeholder>
          </>
        )}

        {animalListNew && (
          <>
            {animalListNew.map((item, index) => {
              return (
                <Col
                  key={index}
                  md={{ span: 6 }}
                  lg={{ span: 4 }}
                  xl={{ span: 3 }}
                  className="mt-0 mt-sm-3">
                  <AnimalItem
                    animalItemContainerRef={animalItemContainerRef}
                    data={item}
                  />
                </Col>
              );
            })}
          </>
        )}
      </Row>
      {moreButton && (
        <>
          <Row>
            <Col sm={12} className="text-center mt-3 mt-sm-5">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/search");
                }}
                className={styles.loadMoreButton}>
                すべて見る <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AnimalList;
