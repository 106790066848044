import { useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight, faPaw } from "@fortawesome/free-solid-svg-icons";

import { useGetDoc } from "../../hooks/useGetDocs";
import PetDetailsShort from "../Pet/PetDetailsShort";
import LoginModal from "../../components/login/LoginModal";
import { useAuthContext } from "../../hooks/useAuthContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ProgressStep from "../../components/progressStep/ProgressStep";

import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import "react-time-picker/dist/TimePicker.css";
import BreederSortDetailsInquery from "../../components/Home/BreederSortDetailsInquery";

export default function Inquiry() {
  const { width } = useWindowDimensions();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();
  const { firebaseGetDoc, getSingleDocWithQuery, isPending } = useGetDoc();

  const [petDatils, setPetDetails] = useState(false);
  const [breederDatils, setBreederDetails] = useState(false);
  const [isSubmitted, setisSubmitted] = useState(false);

  const getPetMCDetails = async () => {
    const microchipArray = await getSingleDocWithQuery("microchip", [
      ["animalID", "==", params.id],
      ["sellPlatform", "==", true]
    ]);
    if (!microchipArray && !microchipArray[0]) {
      // console.log("404");
      return;
    }

    const microchip = microchipArray[0];
    const breeder = await firebaseGetDoc("users", microchip.currentOwnerUID);
    const pet = await getSingleDocWithQuery(
      `users/${microchip.currentOwnerUID}/microchip/`,
      [["mcData.animalID", "==", params.id]],
      ["sellPlatform", "==", true]
    );

    if (breeder && pet && pet[0]) {
      setPetDetails(pet[0]);
      setBreederDetails({ ...breeder, id: microchip.currentOwnerUID });
    } else {
      navigate("/404");
      return;
    }
  };

  const flag = useRef(true);

  useEffect(() => {
    if (flag.current) {
      flag.current = false;
      getPetMCDetails();
    }
  }, []);

  const [showLoginModal, setShowLoginModal] = useState(false);

  let initialValues = {
    visit_date_first_choice: "",
    visit_time_first_choice: null,
    visit_date_second_choice: "",
    visit_time_second_choice: null,
    inquiry_details: "",
    environment: "",
    questions:
      breederDatils &&
      breederDatils.contactQuestions &&
      breederDatils.contactQuestions.map((q) => ({
        ...q,
        checkboxLabels: q.checkboxLabels.map((label) => ({
          label,
          checked: false
        }))
      }))
  };

  const backData = localStorage.getItem("inquiryData");
  if (backData) {
    initialValues = {
      ...initialValues,
      ...JSON.parse(localStorage.getItem("inquiryData"))
    };
  }

  const schema = yup.object().shape({
    visit_date_first_choice: yup.date().required("この欄は必須です"),
    visit_time_first_choice: yup.string().required("この欄は必須です"),
    visit_date_second_choice: yup.date().required("この欄は必須です"),
    visit_time_second_choice: yup.string().required("この欄は必須です"),
    inquiry_details: yup.string().required("この欄は必須です"),
    environment: yup.array(),
    questions: yup.array().of(
      yup.object().shape({
        sectionTitle: yup.string().required("この欄は必須です"),
        checkboxLabels: yup.array().of(
          yup.object().shape({
            label: yup.string().required(),
            checked: yup.bool()
          })
        )
      })
    )
  });

  const handleSubmit = (values) => {
    // console.log(values);
    if (!user) {
      setShowLoginModal(true);
    } else {
      localStorage.setItem("inquiryData", JSON.stringify(values));
      localStorage.setItem("petDatils", JSON.stringify(petDatils));
      localStorage.setItem("breederDatils", JSON.stringify(breederDatils));
      navigate("/inquirycheck");
    }
  };

  return (
    <>
      <div className="cmn-bg">
        <div className="cmn-pastel-yellow-bg">
          <Container>
            <p className="yollow-title fs-5  fw-bold cmn-text-color py-3  text-center text-sm-start">
              <FontAwesomeIcon icon={faPaw} /> 見学希望・お問い合わせフォーム
            </p>
          </Container>
        </div>
        <Container>
          <Row className="justify-content-center mt-5 mb-5">
            <ProgressStep
              props={[
                { value: "内容入力", link: "#" },
                { value: "内容確認", link: "#" },
                { value: "送信完了", link: "#" }
              ]}
              activeNo={1}
            />
          </Row>

          <Row className="justify-content-center ">
            <Col lg={11} className={`${width < 576 ? `p-0` : ``}`}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="py-sm-4 cmn-pastel-yellow-bg">
                      <Row className="mx-sm-4">
                        <Col
                          lg={3}
                          className={`${
                            width < 576 ? `cmn-pastel-yellow-bg py-2 px-5` : ``
                          } ${
                            width >= 576
                              ? `bg-white border-2 border-bottom  border-warning p-4`
                              : ``
                          } fw-bold cmn-text-color`}>
                          見学希望日時{"　"}
                          <Badge bg="dark" text="white" className="rounded-0">
                            必須
                          </Badge>
                        </Col>
                        <Col
                          lg={9}
                          className={`bg-white  ${width < 576 && ` p-5`} ${
                            width >= 576 &&
                            `border-2 border-start border-bottom  border-warning p-4`
                          }  cmn-text-color-ash`}>
                          <ul>
                            <li>
                              ワンちゃんをお迎えするにあたって、現物をご確認頂いた上で、法令で定められた項目を対面で実施しなければなりません（動物愛護法第二十一条の四）
                            </li>
                            <li>
                              見学当日にワンちゃんの受け取りをご希望される場合は、面談予定日の数日前までにご希望をお聞かせ下さい。
                              <br />
                              お引き渡し準備などがございますので必ず事前に販売者ご相談ください。
                            </li>
                            <li>
                              ただし、ワンちゃんのお引渡し日は、生後５７日以降となりますので、その日付を考慮の上、面談日の設定をお願いいたします。（動物愛護法第二十二条の五）
                            </li>
                          </ul>
                          <Row className="justify-content-center">
                            <Col lg={5} className=" mb-3">
                              <Form.Label className="mt-1">
                                【第1希望】
                              </Form.Label>
                              <Row>
                                <Col lg={12}>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text id="basic-addon1">
                                      日付
                                    </InputGroup.Text>
                                    <DatePicker
                                      className={`form-control ${
                                        touched.visit_date_first_choice &&
                                        errors.visit_date_first_choice
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.visit_date_first_choice &&
                                        !errors.visit_date_first_choice
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="yyyy/MM/dd"
                                      minDate={new Date()}
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      yearPlaceholder="年 "
                                      onChange={(val) => {
                                        setFieldValue(
                                          "visit_date_first_choice",
                                          val
                                        );
                                      }}
                                      onFocus={() => {
                                        setFieldTouched(
                                          "visit_date_first_choice",
                                          true
                                        );
                                      }}
                                      value={values.visit_date_first_choice}
                                      name="visit_date_first_choice"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.visit_date_first_choice}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col lg={12}>
                                  <InputGroup className="mt-1" hasValidation>
                                    <InputGroup.Text id="basic-addon1">
                                      時刻
                                    </InputGroup.Text>
                                    <TimePicker
                                      className={`form-control ${
                                        touched.visit_time_first_choice &&
                                        errors.visit_time_first_choice
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.visit_time_first_choice &&
                                        !errors.visit_time_first_choice
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="HH:mm"
                                      locale="ja-JP"
                                      hourPlaceholder="時"
                                      minutePlaceholder="分"
                                      minTime="10:00"
                                      maxTime="22:00"
                                      onInvalidChange={() =>
                                        console.error("Invalid time")
                                      }
                                      disableClock={true}
                                      onChange={(val) => {
                                        setFieldValue(
                                          "visit_time_first_choice",
                                          val
                                        );
                                      }}
                                      onFocus={() => {
                                        setFieldTouched(
                                          "visit_time_first_choice",
                                          true
                                        );
                                      }}
                                      value={values.visit_time_first_choice}
                                      name="visit_time_first_choice"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.visit_time_first_choice}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg={5} className=" mb-3">
                              <Form.Label className="mt-1">
                                【第2希望】
                              </Form.Label>
                              <Row>
                                <Col lg={12}>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text id="basic-addon1">
                                      日付
                                    </InputGroup.Text>
                                    <DatePicker
                                      className={`form-control ${
                                        touched.visit_date_second_choice &&
                                        errors.visit_date_second_choice
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.visit_date_second_choice &&
                                        !errors.visit_date_second_choice
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="yyyy/MM/dd"
                                      minDate={new Date()}
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      yearPlaceholder="年 "
                                      onChange={(val) => {
                                        setFieldValue(
                                          "visit_date_second_choice",
                                          val
                                        );
                                      }}
                                      onFocus={() => {
                                        setFieldTouched(
                                          "visit_date_second_choice",
                                          true
                                        );
                                      }}
                                      value={values.visit_date_second_choice}
                                      name="visit_date_second_choice"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.visit_date_second_choice}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col lg={12}>
                                  <InputGroup className="mt-1" hasValidation>
                                    <InputGroup.Text id="basic-addon1">
                                      時刻
                                    </InputGroup.Text>
                                    <TimePicker
                                      className={`form-control ${
                                        touched.visit_time_second_choice &&
                                        errors.visit_time_second_choice
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.visit_time_second_choice &&
                                        !errors.visit_time_second_choice
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="HH:mm"
                                      locale="ja-JP"
                                      hourPlaceholder="時"
                                      minutePlaceholder="分"
                                      minTime="10:00"
                                      maxTime="22:00"
                                      onInvalidChange={() =>
                                        console.error("Invalid time")
                                      }
                                      disableClock={true}
                                      onChange={(val) => {
                                        setFieldValue(
                                          "visit_time_second_choice",
                                          val
                                        );
                                      }}
                                      onFocus={() => {
                                        setFieldTouched(
                                          "visit_time_second_choice",
                                          true
                                        );
                                      }}
                                      value={values.visit_time_second_choice}
                                      name="visit_time_second_choice"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.visit_time_second_choice}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="mx-sm-4">
                        <Col
                          lg={3}
                          className={`${
                            width < 576 ? `cmn-pastel-yellow-bg py-2 px-5` : ``
                          } ${
                            width >= 576
                              ? `bg-white border-2  border-warning p-4`
                              : ``
                          } fw-bold cmn-text-color`}>
                          お問い合わせ内容{"　"}
                          <Badge bg="dark" text="white" className="rounded-0">
                            必須
                          </Badge>
                        </Col>
                        <Col
                          lg={9}
                          className={`bg-white  ${width < 576 && ` p-5`} ${
                            width >= 576 &&
                            `border-2 border-start  border-warning p-4`
                          }  cmn-text-color-ash`}>
                          <>
                            <div className="pb-3">
                              <Form.Control
                                as="textarea"
                                placeholder="お問い合わせ内容を入力"
                                style={{ height: "180px" }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.inquiry_details}
                                name="inquiry_details"
                                maxLength={150}
                                required
                                isInvalid={
                                  touched.inquiry_details &&
                                  errors.inquiry_details
                                }
                                isValid={
                                  touched.inquiry_details &&
                                  !errors.inquiry_details
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.inquiry_details}
                              </Form.Control.Feedback>
                            </div>

                            <div className="">
                              【よくあるメッセージ例】
                              <p className="mb-2">
                                家族〇人で見学に伺うことは可能でしょうか。{" "}
                                <br />
                                子犬の動画や、親犬の写真を見せていただくことは可能でしょうか。
                              </p>
                              <p
                                className="text-body-secondary "
                                style={{ color: "#808080" }}>
                                ※
                                面談の希望日が難しいようであれば、早めにお知らせください。{" "}
                                <br />※
                                値引き交渉、当該の販売者以外に関するお問い合わせはご遠慮ください。
                              </p>
                            </div>
                          </>
                        </Col>
                      </Row>

                      {breederDatils && breederDatils.contactQuestions && (
                        <>
                          <Row className="mx-sm-4">
                            <p
                              className={`px-3 py-2 m-0 w-100 cmn-text-color text-center text-sm-start ${
                                width < 576 ? `px-4 py-0 fw-bold  bg-white` : ``
                              }`}>
                              子犬を飼育できる環境のお客様のみ販売者へお問い合わせを行うことができます。
                            </p>
                          </Row>
                          {breederDatils.contactQuestions.map(
                            (question, index) => (
                              <>
                                <Row className="mx-sm-4">
                                  <Col
                                    lg={3}
                                    className={`${
                                      width < 576
                                        ? `cmn-pastel-yellow-bg py-2 px-5`
                                        : ``
                                    } ${
                                      width >= 576
                                        ? `bg-white border-2 ${
                                            breederDatils.contactQuestions
                                              .length -
                                              1 !==
                                            index
                                              ? `border-bottom`
                                              : ``
                                          }    border-warning p-4`
                                        : ``
                                    } fw-bold cmn-text-color`}>
                                    {question.sectionTitle}
                                  </Col>
                                  <Col
                                    lg={9}
                                    className={`bg-white  ${
                                      width < 576 && ` p-5`
                                    } ${
                                      width >= 576 &&
                                      `border-2 border-start ${
                                        breederDatils.contactQuestions.length -
                                          1 !==
                                        index
                                          ? `border-bottom`
                                          : ``
                                      }  border-warning p-4`
                                    }  cmn-text-color-ash`}>
                                    <>
                                      <div>
                                        {question.checkboxLabels.map(
                                          (label, labelIndex) => {
                                            return (
                                              <Form.Check
                                                type={"checkbox"}
                                                label={label}
                                                name={`questions[${index}].checkboxLabels[${labelIndex}].checked`}
                                                onChange={handleChange}
                                                checked={
                                                  values.questions &&
                                                  values.questions[index] &&
                                                  values.questions[index]
                                                    .checkboxLabels[labelIndex]
                                                    .checked
                                                }
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                    </>
                                  </Col>
                                </Row>
                              </>
                            )
                          )}
                        </>
                      )}
                    </div>

                    <Row>
                      <Col className={`${width < 576 ? `p-4` : ``}`}>
                        <p className="fs-4 fw-bold cmn-text-color mt-5">
                          <span className="border-5 border-start border-dark ps-2 cmn-text-color">
                            対象のワンちゃん情報
                          </span>
                        </p>
                        <Container className="bg-white p-4 rounded-3">
                          <PetDetailsShort
                            isPending={isPending}
                            petDatils={petDatils}
                          />
                        </Container>

                        <p className="fs-4 fw-bold cmn-text-color mt-5">
                          <span className="border-5 border-start border-dark ps-2 cmn-text-color">
                            ブリーダー情報
                          </span>
                        </p>
                        <Container className="bg-white p-4 rounded-3">
                          <BreederSortDetailsInquery
                            isPending={isPending}
                            breeder={breederDatils}
                            petDatils={petDatils}
                          />
                        </Container>

                        <Container className="cmn-ash-lite-bg mt-5 mb-4 p-4">
                          <p className="fw-bold b-2">
                            お問い合わせにあたっての注意事項
                          </p>
                          <small>
                            <ul>
                              <li className="mb-3">
                                <span className="fw-bold">
                                  販売者とのスムースなやり取りをお願いします{" "}
                                </span>
                                <br />
                                販売者とスムースなやり取りが困難な場合や販売者とトラブルになった場合は、詳細調査の上、当サイトのご利用を制限させていただく場合があります。
                              </li>
                              <li className="mb-3">
                                <span className="fw-bold">
                                  短期間に複数の販売者への問合せはお控えください
                                </span>
                                <br />
                                子犬のお迎えするか見送るかの返事を必ず行ってから、新しい販売者へお問い合わせを行ってください。
                              </li>
                              <li className="mb-3">
                                <span className="fw-bold">
                                  お迎えの優先順について{" "}
                                </span>
                                <br />
                                子犬のお迎え希望が重なった場合、見学申込順ではなく、購入予約順で決まる場合もあります。販売者に直接お問い合わせください。
                              </li>
                            </ul>
                          </small>
                        </Container>

                        <small>
                          <p className="text-center fs-6 px-4">
                            お問い合わせを行うには、
                            <Link to={"/terms-and-conditions/"} className="text-reset text-nowrap">
                              利用規約
                            </Link>
                            ・
                            <Link to={"https://petnext-dev.web.app/privacy-policy"}  className="text-reset text-nowrap">
                              プライバシーポリシー
                            </Link>
                            への同意が必要になります。
                          </p>
                        </small>

                        <Row className="justify-content-center pb-4">
                          <div className="text-center mt-3">
                            <Button
                              className="cmn-yollow-btn mb-2 py-4 "
                              type="submit"
                              onClick={() => setisSubmitted(true)}>
                              {user
                                ? "規約に同意して進む"
                                : "ログインして進む "}
                            </Button>
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    {Object.keys(errors).length && isSubmitted ? (
                      <>
                        <Alert
                          variant="danger"
                          className="w-75 mx-auto text-center">
                          上にスクロールして入力フォームのエラーを確認してください
                        </Alert>
                      </>
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
          <br />
          <br />
          <br />
        </Container>
      </div>
      <LoginModal
        show={showLoginModal && !user}
        modalShowController={setShowLoginModal}
        onHide={() => {
          setShowLoginModal(false);
        }}
      />
    </>
  );
}
