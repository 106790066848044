import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import PopularDogBreedType from "../../components/Home/PopularDogBreedType";
import { Container } from "react-bootstrap";
const AllBreed = () => {
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();
  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "LOOKFOR", link: "/" });
    breadcrumbItem.push({ name: "All Breed" });
    setBreadcrumb(breadcrumbItem);
  }, []);
  return (
    <>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}
      <div className="mb-3 mb-sm-5">
        <Container>
          <PopularDogBreedType
            moreButton={false}
            limit={false}
            fulWidth={true}
          />
        </Container>
        {width <= 576 && (
          <>
            <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
          </>
        )}
      </div>
    </>
  );
};

export default AllBreed;
