import React, { useEffect, useState } from "react";
import styles from "./SideBarRatingItem.module.css";
import { Col, Row } from "react-bootstrap";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Rating } from "@mui/material";
import { useGetDoc } from "../../../hooks/useGetDocs";
import { getName } from "../../../utils/Utils";
import StarRatings from "react-star-ratings";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

const SideBarRatingItem = ({ review }) => {
  const { firebaseGetDoc, isPending, error } = useGetDoc();

  const [breeder, setBreeder] = useState();
  const [owner, setOwner] = useState();

  useEffect(() => {
    firebaseGetDoc("users", review.breeder_id).then((res) => {
      setBreeder(res);
    });

    firebaseGetDoc("users", review.owner_id).then((res) => {
      setOwner(res);
    });
  }, []);

  let data = {
    image: "/assets/img/ratingDog.png",
    breederCount: "◯◯ ◯◯",
    text: "チワワ（スムース）", //Couldn't understand this data so I name it "text"
    rating: 5,
    details:
      "メッセージのやり取りもスムーズでしたし、仔犬の説明などもきちんとしてくれたので良かったです。",
    date: "2023/6/10",
    time: "13:20",
    address: "東京都",
    name: "H.N様"
  };
  const { width } = useWindowDimensions();

  return (
    <div>
      {breeder && owner && (
        <Row className="my-2 my-sm-0">
          <Col xl={4} sm={8} xs={3} className={`${width <= 576 ? "p-0" : ""}`}>
            <div className="ratio ratio-1x1">
              <img
                src={
                  breeder.img || "/assets/img/frontend/golden_retriever.png"
                }
                className="w-100"
              />
            </div>
          </Col>

          <Col xl={8} sm={12} xs={9}>
            <p className={styles.breederCount}>
              {getName(breeder, 6)}{" "}
              <span className={styles.breederCountTxt}>ブリーダー</span>
            </p>
            <p className={styles.ratingCount}>
              <StarRatings
                rating={review.rating}
                starDimension="22px"
                starSpacing="0px"
                starRatedColor="#FAAF00"
              />
            </p>
          </Col>
          <Col sm={12} className={`mt-1 ${width <= 576 ? "p-0" : ""}`}>
            <p className={styles.breederDetails}>{review.review}</p>
            <p className={styles.address}>
              {moment(
                new Timestamp(
                  review.reviewAt.seconds,
                  review.reviewAt.nanoseconds
                ).toDate()
              ).format("YYYY/MM/DD HH:mm")}{" "}
              {breeder.prefecture} {getName(owner, 6)}様
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SideBarRatingItem;
