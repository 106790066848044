import { useState } from "react";

import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";



import Home from "./pages/home/Home";
import Inquiry from "./pages/Inquiry/Inquiry";
import PetDetails from "./pages/Pet/PetDetails";
import AllBreed from "./pages/AllBreed/AllBreed";
import Company from "./pages/StaticPage/Company";
import LoginWithCode from "./pages/LoginWithCode";
import Footer from "./components/template/Footer";
import ToastDiv from "./components/toast/ToastDiv";
import BreedAnimal from "./pages/AllBreed/BreedAnimal";
import AllBreeder from "./pages/AllBreeder/AllBreeder";
import InquiryCheck from "./pages/Inquiry/inquirycheck";
import { useAuthContext } from "./hooks/useAuthContext";
import Header from "./components/template/Header/Header";
import SearchBreed from "./pages/AllBreeder/SearchBreeder";
import BreederDetails from "./pages/Breeder/BreederDetails";
import SearchContainer from "./pages/Search/SearchContainer";
import SearchBreeder from "./pages/AllBreeder/SearchBreeder";
import InquiryComplete from "./pages/Inquiry/inquirycomplete";
import { UserDetailsProvider } from "./context/UserDetailsContext";
import TermsAndCondition from "./pages/StaticPage/TermsAndCondition";
import CommercialTransactionsLaw from "./pages/StaticPage/CommercialTransactionsLaw";

import "react-quill/dist/quill.snow.css";

import "./App.css";
import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { user, authIsReady } = useAuthContext();
  return (
    <div className="main">
      <Helmet>
        <title>LOOKFOR | 犬の新しい出会いをサポートするマッチングサイト</title>
        <meta
          name="description"
          content={
            "LOOKFORでは、全国の優良ブリーダーからペットとの理想的な出会いをサポートするマッチングサイトです。あなたと新しい家族となるペットとのつながりをお手伝いします。ペットとの素敵な出会いを今すぐ見つけましょう。"
          }
        />
      </Helmet>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />

            <Route
              path="/login/:code"
              element={
                !user ? (
                  <>
                    <UserDetailsProvider>
                      <Header />
                    </UserDetailsProvider>
                    <LoginWithCode />
                  </>
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            <Route
              path="/all-breeder/"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <AllBreeder />
                  <Footer />
                </>
              }
            />
            <Route
              path="/breeder/:id"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <BreederDetails />
                  <Footer />
                </>
              }
            />

            <Route
              path="/pet/:id"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <PetDetails />
                  <Footer />
                </>
              }
            />

            <Route
              path="/search"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <SearchContainer />
                  <Footer />
                </>
              }
            />

            <Route
              path="/inquiry/:id"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <Inquiry />
                  <Footer />
                </>
              }
            />

            <Route
              path="/inquirycheck"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                    <InquiryCheck />
                  </UserDetailsProvider>
                  <Footer />
                </>
              }
            />

            <Route
              path="/inquirycomplete"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                    <InquiryComplete />
                  </UserDetailsProvider>
                  <Footer />
                </>
              }
            />

            <Route
              path="/all-breed/"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <AllBreed />
                  <Footer />
                </>
              }
            />
            <Route
              path="/search/breeder/"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <SearchBreeder />
                  <Footer />
                </>
              }
            />
            <Route
              path="/breed/:id"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <BreedAnimal />
                  <Footer />
                </>
              }
            />

            <Route
              path="/company"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <Company />
                  <Footer />
                </>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <TermsAndCondition />
                  <Footer />
                </>
              }
            />

            <Route
              path="/law"
              element={
                <>
                  <UserDetailsProvider>
                    <Header />
                  </UserDetailsProvider>
                  <CommercialTransactionsLaw />
                  <Footer />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
      <ToastDiv />
    </div>
  );
}

export default App;
