import React, { useEffect, useRef, useState } from "react";

import lozad from "lozad";
import moment from "moment";
import StarRatings from "react-star-ratings";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { Timestamp } from "firebase/firestore";

import { getName } from "../../utils/Utils";
import styles from "./BreederInfo.module.css";
import { useReview } from "../../hooks/useReview";
import { dog_breed } from "../../utils/CatDogData";
import useQueryDocs from "../../hooks/useQueryDocs";
import { useAggregation } from "../../hooks/useAggregation";
import AddressFormatter from "../../components/AddressFormatter";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import ReviewItem from "../../components/BreaderDetails/ReviewItem";
import RatingProgressBar from "../../components/Universal/RatingProgressBar";
import { AnimalItemListGrid } from "../../components/Universal/AnimalItemListGrid";
import { Helmet } from "react-helmet";

const BreederInfo = ({ breadcrumb, userName }) => {
  const { docData, pending, error, fetchItems } = useQueryDocs();
  const {
    getReviewDetails,
    isPending: reviewPending,
    error: reviewError
  } = useReview();
  const { documents, isPending, lastDoc, isLast, getMultipleDocs } =
    useCollectionDocs();

  const { countWithQuery } = useAggregation();

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const observer = lozad();

  const descriptionContainerRef = useRef(null);
  const descriptionRef = useRef(null);

  const [mobileDescriptionExpand, setMobileDescriptionExpand] = useState(false);
  const [
    showmobileDescriptionExpandButton,
    setShowMobileDescriptionExpandButton
  ] = useState(false);

  const [state, setState] = useState({
    id: "",
    firstName: "",
    lastName: "",
    firstKana: "",
    lastKana: "",
    gender: "",
    image: "/assets/img/frontend/loading.gif",
    zip3: "",
    zip4: "",
    animal_handle_1: [],
    animal_handle_2: [],
    about: "",
    createDate: "",
    updateDate: "",
    totalMC: 0
  });

  const [breederDatils, setBreederDetails] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      await fetchItems("users", { lookforusername: userName });
    };
    const loadReviewDetails = async (breederID) => {
      const { ratingAve, totalReview } = await getReviewDetails(breederID);
      setRating(ratingAve);
      setReviewCount(totalReview);

      const total1StartReview = await countWithQuery(
        `users/${breederID}/reviews/`,
        [["rating", "==", 1]]
      );

      const total2StartReview = await countWithQuery(
        `users/${breederID}/reviews/`,
        [["rating", "==", 2]]
      );

      const total3StartReview = await countWithQuery(
        `users/${breederID}/reviews/`,
        [["rating", "==", 3]]
      );

      const total4StartReview = await countWithQuery(
        `users/${breederID}/reviews/`,
        [["rating", "==", 4]]
      );

      const total5StartReview = await countWithQuery(
        `users/${breederID}/reviews/`,
        [["rating", "==", 5]]
      );
      setRating1(total1StartReview);
      setRating2(total2StartReview);
      setRating3(total3StartReview);
      setRating4(total4StartReview);
      setRating5(total5StartReview);
    };
    if (!docData) {
      loadData();
    } else {
      if (Array.isArray(docData) && docData.length > 0) {
        loadReviewDetails(docData[0].id);
        loadMoreReview(docData[0].id);
        setBreederDetails(docData[0]);
        attachData(docData[0]);
      } else {
        navigate("/404");
      }
    }
  }, [docData]);

  const attachData = (data) => {
    // console.log("breeder", data);
    setState({
      ...state,
      id: data.id || "",
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      firstKana: data.firstKana || "",
      lastKana: data.lastKana || "",
      gender: data.gender || "",
      image: data.img || data.image || "/assets/img/frontend/golden_retriever.png",
      zip3: data.zip3 || "",
      zip4: data.zip4 || "",
      animal_handle_1: data.animal_handle_1 || [],
      animal_handle_2: data.animal_handle_2 || [],
      about: data.about || "",
      createDate: moment(
        new Timestamp(
          data.createdAt.seconds,
          data.createdAt.nanoseconds
        ).toDate()
      ).format("YYYY年MM月DD日"),
      updateDate: moment(
        new Timestamp(
          data.createdAt.seconds,
          data.createdAt.nanoseconds
        ).toDate()
      ).format("YYYY年MM月DD日"),
      totalMC: data.totalMC
    });
  };

  useEffect(() => {
    if (state.id != "") {
      if (
        descriptionContainerRef.current.clientHeight <
        descriptionRef.current.clientHeight
      ) {
        setShowMobileDescriptionExpandButton(true);
      }
    }
  }, [width, state.about]);

  useEffect(() => {
    observer.observe();
  });

  function getDogBreed(value) {
    const foundItem = dog_breed.find((item) => item.value === value);
    if (foundItem) {
      return foundItem.label;
    } else {
      return "";
    }
  }

  const limit = 10;
  useEffect(() => {
    // console.log(documents);
    if (documents) {
      let newreviews = reviews;
      documents.forEach((doc) => {
        newreviews = { ...newreviews, [doc.id]: doc };
      });
      setReviews(newreviews);
    }
  }, [documents]);

  const loadMoreReview = (breederID) => {
    getMultipleDocs({
      _collection: `users/${breederID}/reviews`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "desc",
      _limit: limit,
      _startAfter: lastDoc || false
    });
  };

  return (
    <>
      {state.id == "" && (
        <div className={styles.loaderContainer}>
          <div className="position-fixed top-50 start-5">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {state.id != "" && (
        <>
          <Helmet>
            <title>
              LOOKFOR |{" "}
              {`${state.lastName || ""} ${
                state.firstName || ""
              } ブリーダーの詳細情報`}
            </title>
          </Helmet>
          <Container className="mt-2 mt-sm-5">
            <Row className="mt-0 pt-0 align-items-end">
              <Col xs={12} sm={6}>
                <div className={styles.dataDates}>
                  <p>掲載日：{state.createDate}</p>{" "}
                  <p>更新日：{state.updateDate}</p>
                </div>
                <h4 className={styles.heading}>
                  {state.lastName} {state.firstName} ブリーダーの詳細情報
                </h4>
              </Col>
            </Row>
            <Row className="mt-2 mt-sm-2">
              <Col sm={4}>
                <div className="ratio ratio-1x1 my-2">
                  <img
                    src={state.image}
                    className={`lozad img-fluid object-fit-cover ${styles.mainImg} `}
                  />
                </div>
              </Col>

              <Col
                sm={8}
                className="d-flex justify-content-center flex-column mb-4 mb-sm-0 mt-4 mt-sm-0"
              >
                <div className="w-100">
                  <table className={styles.table}>
                    <tbody>
                      <tr>
                        <td>総合評価</td>
                        <td>
                          <div className="d-flex w-100 align-items-center">
                            <StarRatings
                              rating={rating}
                              starDimension="22px"
                              starSpacing="0px"
                              starRatedColor="#FAAF00"
                            />
                            <span className={styles.rating}>
                              {rating ? parseFloat(rating).toFixed(1) : "0.0"}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>口コミ数</td>
                        <td>{reviewCount || 0}件</td>
                      </tr>
                      <tr>
                        <td>犬舎所在地</td>
                        <td>
                          {/* Span will bring this sign ">" */}
                          {/* <div className={styles.address}>
                          {state.prefecture != "" && (
                              <span>{state.prefecture}</span>
                            )}

                            {state.addressLine1 != "" && (
                              <span>{state.addressLine1}</span>
                            )}
                          </div> */}

                          <AddressFormatter
                            zip3={state.zip3}
                            zip4={state.zip4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>取扱犬種</td>
                        <td>
                          {/* Span will bring this sign "," */}
                          <div className={styles.handleBreeds}>
                            {state.animal_handle_1.map((item, i) => {
                              return getDogBreed(item) != "" ? (
                                <span key={i}>{getDogBreed(item)}</span>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>全犬種</td>
                        <td>ワンちゃん一覧 {state.totalMC}件</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>

          <div className={styles.BG_GRAY}>
            <Container className="mb-1 mb-sm-4">
              <Row>
                <Col sm={12}>
                  <h4 className={styles.heading}>犬舎紹介</h4>
                </Col>
                <Col sm={12}>
                  <div
                    ref={descriptionContainerRef}
                    className={`w-100 bg-white rounded-3 p-4 mt-3 ${
                      width <= 576 && !mobileDescriptionExpand
                        ? styles.mobileDescriptionCollaps
                        : styles.mobileDescriptionUnCollaps
                    }`}
                  >
                    <div
                      ref={descriptionRef}
                      className={`${styles.description} ql-editor p-0`}
                      dangerouslySetInnerHTML={{ __html: state.about }}
                    ></div>

                    {width <= 576 && (
                      <>
                        {showmobileDescriptionExpandButton && (
                          <>
                            <div className={styles.expandButton}>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMobileDescriptionExpand(true);
                                  setShowMobileDescriptionExpandButton(false);
                                }}
                                href="#"
                              >
                                続きを読む
                              </a>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="mt-5">
              <AnimalItemListGrid
                breederName={getName(breederDatils)}
                id={state.id}
              />
            </div>
          </div>

          <Container className="mt-3 mt-sm-5">
            <Row>
              <Col sm={12}>
                <h4 className={styles.heading}>
                  {state.lastName} {state.firstName} ブリーダーの
                  <br className="d-block d-sm-none"></br>
                  総合評価・お客様の声
                </h4>
              </Col>
            </Row>

            <Row className={styles.RatingInfo}>
              <Col xs={5} sm={3} className="d-flex justify-content-end">
                <div className={styles.details}>
                  <p>{rating ? parseFloat(rating).toFixed(1) : "0.0"}</p>
                  {width > 576 && (
                    <StarRatings
                      rating={rating}
                      starDimension="30"
                      starSpacing="0px"
                      starRatedColor="#FAAF00"
                    />
                  )}

                  {width <= 576 && (
                    <StarRatings
                      rating={rating}
                      starDimension="22"
                      starSpacing="0px"
                      starRatedColor="#FAAF00"
                    />
                  )}
                  <p className={styles.ratingTotalTXT}>
                    口コミ数 &nbsp; <u>{reviewCount || 0}件</u>
                  </p>
                </div>
              </Col>
              <Col
                xs={7}
                sm={5}
                className="d-flex flex-column justify-content-start ps-0"
              >
                <div className={styles.ProgressBarHolder}>
                  <span>5</span>
                  <RatingProgressBar
                    total_review={reviewCount}
                    gained_review={rating5}
                  />
                </div>
                <div className={styles.ProgressBarHolder}>
                  <span>4</span>
                  <RatingProgressBar
                    total_review={reviewCount}
                    gained_review={rating4}
                  />
                </div>
                <div className={styles.ProgressBarHolder}>
                  <span>3</span>
                  <RatingProgressBar
                    total_review={reviewCount}
                    gained_review={rating3}
                  />
                </div>
                <div className={styles.ProgressBarHolder}>
                  <span>2</span>
                  <RatingProgressBar
                    total_review={reviewCount}
                    gained_review={rating2}
                  />
                </div>
                <div className={styles.ProgressBarHolder}>
                  <span>1</span>
                  <RatingProgressBar
                    total_review={reviewCount}
                    gained_review={rating1}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                className="d-flex align-items-center mt-4 mb-2 my-sm-5"
              >
                <a href="#" className={styles.seeAllLink}>
                  投稿日が新しい順
                  <span className="material-symbols-outlined">expand_more</span>
                </a>
              </Col>
            </Row>

            {Object.values(reviews).length > 0 && (
              <>
                {Object.values(reviews) &&
                  Object.values(reviews).map((doc, i) => {
                    return <ReviewItem key={i} review={doc} />;
                  })}
              </>
            )}

            <div className="d-flex justify-content-center my-5">
              {!isLast && !isPending && limit < reviews.length && (
                <button
                  className={styles.loadMoreReviewButton}
                  onClick={loadMoreReview}
                >
                  さらに過去の評価を見る
                </button>
              )}
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default BreederInfo;
