import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";

import { BorderColor } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import styles from "./FavPet.module.css";
import useFavourite from "../../hooks/useFavourite";
import LoginModal from "../../components/login/LoginModal";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const FavPet = (props) => {
  const {
    isReady,
    isFavourite,
    isPending,
    addToFavourite,
    removeFromFavourite,
    totalFav
  } = useFavourite(props.id);
  let fullWidth = props.fullWidth;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <>
      {isReady && (
        <>
          {!isFavourite && (
            <>
              <Button
                disabled={isPending}
                onClick={addToFavourite}
                className={`btn btn-light bg-light border border-1 border-dark rounded-0 ${
                  styles.button
                } ${fullWidth ? "w-100" : ""}`}
              >
                <FavoriteBorderOutlinedIcon /> お気に入りに登録する
              </Button>
              <p className={`mt-2 ${styles.count}`}>
                お気に入り登録数 {totalFav} 人
              </p>
            </>
          )}

          {isFavourite && (
            <>
              {width > 576 && (
                <>
                  <Button
                    disabled={isPending}
                    onClick={removeFromFavourite}
                    className={`btn btn-light bg-light border border-1 border-dark rounded-0 ${
                      styles.button
                    } ${fullWidth ? "w-100" : ""}`}
                  >
                    <FavoriteIcon style={{ color: "#FF5858" }} />{" "}
                    お気に入りから削除
                  </Button>
                  <p className={`mt-2 ${styles.count}`}>
                    お気に入り登録数 {totalFav} 人
                  </p>
                </>
              )}

              {width <= 576 && (
                <>
                  <Button
                    disabled={isPending}
                    onClick={removeFromFavourite}
                    className={`btn btn-light border border-1 rounded-0 text-white ${
                      styles.button
                    } ${fullWidth ? "w-100" : ""}`}
                    style={{
                      backgroundColor: "#FF5858",
                      BorderColor: "#FF5858"
                    }}
                  >
                    <FavoriteIcon style={{ color: "#FFFFFF" }} />{" "}
                    お気に入りから削除
                  </Button>
                  <p className={`mt-2 ${styles.count}`}>
                    お気に入り登録数 {totalFav} 人
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}

      {!isReady && (
        <>
          <Button
            onClick={() => {
              setShowLoginModal(true);
            }}
            className="btn btn-light bg-light border border-1 border-dark rounded-0"
          >
            <FontAwesomeIcon icon={faHeart} /> お気に入りに登録する
          </Button>
          <p className="mt-2">お気に入り登録数 {totalFav} 人</p>
        </>
      )}

      <LoginModal
        show={showLoginModal && !isReady}
        onHide={() => {
          setShowLoginModal(false);
        }}
        modalShowController={setShowLoginModal}
      />
    </>
  );
};

export default FavPet;
