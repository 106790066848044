import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Col, Container, Placeholder, Row } from "react-bootstrap";

import { useCollection } from "../../hooks/useCollection";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "../../components/Home/PopularBreeder.module.css";
import PopularBreederItem from "../../components/Home/PopularBreederItem";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";

const AllBreeder = () => {
  const [breeder, setBreeder] = useState(null);
  const { width } = useWindowDimensions();
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { documents: BreederList } = useCollection(
    "users",
    ["group", "==", "breederpet"],
    "transferMNY",
    "desc"
  );

  useEffect(() => {
    if (BreederList) {
      setBreeder(BreederList);
    }
  }, [BreederList]);

  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "LOOKFOR", link: "/" });
    breadcrumbItem.push({ name: "すべてのブリーダー" });
    setBreadcrumb(breadcrumbItem);
  }, []);

  return (
    <>
      <Helmet>
        <title>LOOKFOR - すべてのブリーダーs </title>
      </Helmet>

      <div className={`${styles.grayArea} pb-2`}>
        {width > 576 && (
          <>
            <CustomBreadcrumb Breadcrumb={breadcrumb} />
          </>
        )}

        <Container>
          <div className="mt-sm-4 pb-sm-5">
            <h5 className={styles.title}>人気のブリーダー</h5>
            <Row>
              {!breeder && (
                <>
                  <Placeholder animation="glow">
                    {Array.from({ length: 8 }, (_, i) => (
                      <Placeholder
                        key={i}
                        sm={{ span: 12 }}
                        className="rounded-2 p-4 my-3"
                        style={{ height: "400px" }}
                      />
                    ))}
                  </Placeholder>
                </>
              )}

              {breeder && (
                <>
                  {breeder.map((item, i) => {
                    return (
                      <>
                        {item.lookforusername && (
                          <Col key={i} sm={12} className="mt-4">
                            <PopularBreederItem
                              breeder={item}
                              fulColumnWidth={true}
                              withList={true}
                            />
                          </Col>
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </Row>
          </div>

          {width <= 576 && (
            <>
              <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default AllBreeder;
